<!-- eslint-disable -->
<template>
  <DocPage :titles="titles" title="Terms of Use">
    <div>
      <h2 id="Introduction">Introduction</h2>
      <br /><br />

      Please carefully read these Terms and Conditions ("Terms") before using the services provided by Inst Money ("Inst",
      "we", "us", or "our").<br /><br />
      Inst Money, a licensed financial service, is an early mover in the crypto ecosystem. The services offered by Inst
      Money, including but not limited to, access to our website, use of our technology and platform, and the buying and
      selling of crypto, are part of the rich array of solutions we provide.<br /><br />
      By accessing or using our services, whether it be our website, API applications, or any other platforms collectively
      referred to as the "Services", you ("User", "you") agree to be bound by these Terms, our Privacy Policy, and all
      applicable laws and regulations.<br /><br />
      We reserve the right, in our sole discretion, to make changes to these Terms. Any such modifications will be
      effective immediately upon posting. Thus, it is your responsibility to review these Terms periodically. Your
      continued use of the Services following any such modifications constitutes your acceptance of the revised
      Terms.<br /><br />
      By accessing or using the Services, you affirm that you are at least 18 years old or of the minimum legal age to
      enter into contracts in your jurisdiction, whichever is higher ("Minimum Age"), and possess the right, capacity, and
      authority to enter into this agreement.<br /><br />
      If you do not agree to these Terms, you are not authorized to access or use the Services.<br /><br />
      All Services are operated and maintained by Unioncash EU UAB (Registry code 306049758), under which Inst Money is a
      brand.<br /><br />

      <!-- Please read this document carefully, as it sets out the terms and
      conditions on which Inst Money (referred to as "INST") will provide
      services to you through our websites or APIs applications.<br /><br />
      1. Inst Money, a licensed financial service，Is one of the first movers in
      the crypto ecosystem to be awarded Principal Member of the Visa/MC
      network. ("INST", "we", "us", or "our")<br /><br />
      2. By accessing or using our website or any applications and services
      provided by INST (collectively, the "Service"), you agree to this Terms of
      Use and the incorporated Privacy Policy as well as all applicable laws and
      regulations. Your use of the Service is at all times subject to this Terms
      of Use. PLEASE READ THIS TERMS OF USE CAREFULLY.<br /><br />
      3. We may change this Terms of Use in our sole discretion at any time by
      publishing an updated version here. Any changes to this Terms of Use will
      take effect and be binding on you from the time of posting the updated
      version. Therefore, it is your obligation to periodically review any
      changes that may be made. Your continued use of the Service constitutes
      your agreement to be bound by any changes to this Terms of Use. If you do
      not agree with this Terms of Use, you are not authorized to access or use
      the Service.<br /><br />
      4. By accessing or using the Service, you confirm that you are at least 18
      years old or the minimum age in your jurisdiction to have the legal
      capacity to enter into contracts, whichever is older ("Minimum Age"), and
      have the right, capacity, and authority to enter into a contract.<br /><br /> -->
      <h2 id="I. About the Service">I. About the Service</h2>
      <br /><br />
      INST is a blockchain-based application, which will help you to access the
      crypto services for connecting your users effortlessly，including crypto
      purchase channels to users, offer crypto cards channels and introduce
      providers that accept crypto payment from multiple services. <br /><br />
      1.By accepting INST’s services and agreeing with these Terms of Use, you
      are explicitly giving INST permission to connect you through API with
      crypto purchase or payment institution(“the third-party institution”) that
      are cooperating with INST and help to fulfil your order for Cryptocurrency
      as instructed. <br /><br />
      2. You understand that by initiating transactions each time in INST
      platform, the third-party institution will act as your agent to purchase
      cryptocurrency on your behalf and send/deliver the cryptocurrency to the
      wallet address indicated in the order subject to the conditions of the
      Terms of Use set out in platform of INST and the third-party
      institutions.<br /><br />
      3. At no point during the transaction will INST be in possession or in
      control of client funds. <br /><br />
      4. INST DOES NOT provide Crypto / Digital Asset wallets, nor does INST
      host wallets or is custodian of funds or assets.<br /><br />
      5. Purchases / orders through INST are one-offs and executed individually,
      one by one.<br /><br />
      6. Together, the services set out in clause 3 are referred to in this
      Agreement as the "Services".<br /><br />
      <br />
      <h2 id="II. Your Rights and Obligations">
        II. Your Rights and Obligations
      </h2>
      <br /><br />
      1. In accordance with applicable laws and regulations, you may be required
      to provide relevant information (e.g. name, date of birth，Passport, phone
      number, bank card number and etc.) when you use the Service. You may be
      denied access to the Service if you do not provide the required
      information. Any risks, liabilities, losses, costs, etc., arising from not
      doing so will be of your full responsibility.<br /><br />
      2. We may develop different versions of INST for different devices. You
      should download the appropriate version according to your needs. We cannot
      guarantee the functionality or security if you obtain INST from an
      unlicensed third party or downloads the different software with the same
      name.<br /><br />
      3. After the release of a new version of INST, the old version may no
      longer be usable. We cannot guarantee the security, continued
      functionality, and provision of customer support for the old version of
      INST. Please update to the latest version in a timely manner. Any risks,
      liabilities, losses, costs, etc., arising from not doing so will be of
      your full responsibility.<br /><br />
      4. You should keep your own mobile devices, account password, private key,
      and other personal details safe. We are not responsible for the
      safekeeping of these. All risks, liabilities, losses, and costs arising
      from: loss of mobile devices; active disclosure, passive disclosure, or
      loss of password or private key; or other attacks or fraud shall be of
      your full responsibility.<br /><br />
      5. You agree to follow INST's in-app prompts. Any risks, liabilities,
      losses, costs, etc., arising from not doing so will be of your full
      responsibility.<br /><br />
      6. You accept that we are not obliged to perform due diligence on third
      party authentication services.<br /><br />
      7. If we have reasonable grounds to believe that your actions or situation
      are abnormal, your identity information is in question, or if we require
      you to verify your identity card or other documents, please cooperative
      with us to verify your identity and provide documents to complete the
      authentication in a timely manner.<br /><br />
      8. When your identity is being used you will receive a data usage request,
      please check the request and reply. In order to avoid receiving too many
      authorization requests, We have designed an authorization agency
      mechanism. INST will perform automatic auditing of requests and filter
      requests according to your settings.<br /><br />
      9. When you operate INST or use identity authentication services on INST,
      you are required to comply with the applicable laws and regulations.<br /><br />
      10. INST will make notifications through website announcements, emails,
      text messages, website messages, pop-ups, etc. Please take notice of them
      and follow the instructions.<br /><br />
      11. We will not charge any fee of the Service for the time being. If in
      the future service fees for certain features are introduced, you will be
      notified.<br /><br />
      12. The user explicitly agrees that the loading of funds onto the card will be completed within a 24-hour period and
      is exclusively intended for account replenishment. Any subsequent currency conversions or purchases following the
      completion of the replenishment are entirely independent of the original card transaction during fund
      loading.<br /><br />
      <br />
      <h2 id="III. Risk Warning">III. Risk Warning</h2>
      <br /><br />
      1. When using the Service, if you or your counterparty do not comply with
      the operating instructions, rules, etc. in this Terms of Use, we do not
      guarantee that the Service will be successfully completed and will not be
      liable for any damages. In that case, you and your counterparty shall bear
      the consequences due to the irreversible nature of blockchain.<br /><br />
      2. When you use third party authentication services on INST, we recommend
      that you carefully read this Terms of Use and the notices on INST to
      better understand service providers and product information, and carefully
      evaluate risks before taking any action. A binding contractual
      relationship will be established between you and your counterparts,
      instead of us. We will not take any responsibility in any risks,
      liabilities, losses, or expenses caused by your authentication
      activities.<br /><br />
      3. You understand that when you create or import an account on INST your
      private key and other information are only stored on the current mobile
      device and are not stored on INST or by us. You can switch device and
      import your account according to the INST guidance. However, if you do not
      save or back up your account password, private key, etc., and your mobile
      device is lost, your account will be lost and we will not be able to
      retrieve it. You will be responsible for the loss incurred. If you lose
      your password whilst exporting, saving, or backing up your account or
      private key, or if the device or server that saves or backs up the above
      information is hacked or you lose control of it otherwise, your account
      will be lost and we will not be able to recover it for you. You will be
      responsible for the loss incurred.<br /><br />
      4. We recommend that you securely back up your account password, private
      key, and other information when creating or importing an account. Please
      do not use the following backup methods: screenshots, emails, notepads,
      SMS, messaging applications, or other electronic backup methods. We
      recommend that you copy the relevant information in a paper notebook.<br /><br />
      5. We recommend that you use INST in a secure network environment to avoid
      potential security risks.<br /><br />
      6. By accepting services in INST platform, you understand that INST will
      not purchase, exchange, sale any cryptocurrency directly for you and all
      the crypto related services will be provided by the third-party
      institution. INST will check with prudence the legal capacity or relevant
      certificate of the third-party institutions to carry out crypto related
      services, but this examination will only base on the information provided
      by the third-party institutions. By accepting services in INST
      platform，you accept that in no case shall INST be liable for any losses,
      whether direct or indirect, resulting from the services provided by the
      third-party institution.<br /><br />
      7. When using the Service, please be alert to fraudulent software or
      activity online. If such behavior is discovered, we encourage you to
      inform us as soon as possible.<br /><br />
      <br />
      <h2 id="IV. Service Change, Interruption, Termination">
        IV. Service Change, Interruption, Termination
      </h2>
      <br /><br />
      1. 1.You agree that we may temporarily provide certain service functions
      to ensure business operations or might suspend some service functions or
      start new service functions in the future. Whenever any function
      experiences a change, as long as you continue to use the Service provided
      by us, you are assumed to continually accept this Terms of Use.<br /><br />
      2. You understand that INST will suspend, restrict or terminate the
      Service without being held liable under the following situations:<br />
      (1) When there is business interruption due to technical issues such as
      equipment maintenance, blockchain system maintenance, upgrades, failures,
      or communication interruptions;<br />
      (2) Due to force majeures such as typhoons, earthquakes, tsunamis, floods,
      blackouts, wars or terrorist attacks, viruses, trojans, hacker attacks,
      system instability or government intervention, etc., which prevent us from
      providing the Service or lead us to believe that continuing the Service
      would pose a risk;<br />
      (3) Other situations which we cannot control or foresee;<br /><br />
      3. We may suspend, restrict or terminate your use of some or all INST
      features without being held liable if you:<br />
      (1) Pass away;<br />
      (2) Fraudulently use someone else's account information or mobile
      device;<br />
      (3) Provide false information when filling out information or someone
      other than you fills out your information;<br />
      (4) Refuse mandatory updates for INST functions;<br />
      (5) Refuse mandatory updates for INST functions;<br />
      (6) Hinder other Users’ INST function usage;<br />
      (7) Impersonate INST employees or admins;<br />
      (8) Attack, invade, alter, or threaten the normal operation of INST in any
      way;<br />
      (9) Use INST to post spam advertisements;<br />
      (10) Spread rumors which cause damage to INST and INST employee;<br />
      (11) Perform illegal activities, other violations of this Terms of Use,
      and circumstances where suspension is reasonable.<br /><br />
      4. When the service relationship between you and us is changed,
      interrupted, or terminated, you will still have the right to export your
      account and other information within a reasonable time.<br /><br />
      <br />
      <h2 id="V. Your Representations and Warranties">
        V. Your Representations and Warranties
      </h2>
      <br /><br />
      1. You shall comply with the applicable laws and regulations. You shall
      not use INST for any illegal or unlawful purposes, or by any illegal or
      unlawful means including but not limited to:<br />
      (1) Money laundering, illegal fundraising, etc.;<br />
      (2) Collecting or processing the content provided by us, intervening or
      attempting to intervene with any users by the use of any automated
      programs, software, network engines, web crawlers, web analytics tools,
      data mining tools, or similar tools;<br />
      (3) Invading into other user’s account for fraudulent use of identity or
      stealing digital assets;<br />
      (4) Engaging in any inproper or false transactions with the counterparty;
      committing any activities which harm or attempts to harm INST service
      system and data;<br />
      (5) Other activities which we have reason grounds to believe are
      inappropriate.<br /><br />
      2. You understand and accept that you shall be responsible for any
      violation of the applicable laws and regulations or for breach of this
      Terms of Use by you and shall indemnify us for the losses, third party
      claims, or administrative penalties against us incurred by such violation
      or breach, including reasonable attorney’s fees.<br /><br />
      3. You confirm that you will pay the service fees charged by us in time
      (if applicable). We reserve the right to suspend the Service when you fail
      to pay service fees (if applicable).<br /><br />
      <br />
      <h2 id="VI.Disclaimer and Limitation of Liability">
        VI.Disclaimer and Limitation of Liability
      </h2>
      <br /><br />
      1. You acknowledge and accept that we shall not be held liable for any losses, whether direct or indirect, in
      connection to any aspects which is not within the INST’s direct control.
      <br />
      The user acknowledges and consents to the provision allowing the temporary suspension of withdrawals in specific
      events or malfunctions. In instances such as market volatility or similar circumstances, the company reserves the
      right to suspend user withdrawals temporarily to ensure the security of both accounts and transactions.
      Including but not limited to the following (or failure of the following, as applicable) :<br />
      (1) system maintenance or upgrading of INST;<br />
      (2) force majeures, such as typhoons, earthquakes, flooding, lightning,
      terrorist attacks, etc.;<br />
      (3) malfunction of your mobile device hardware and software, and failure
      of telecommunication lines and power supply lines;<br />
      (4) your improper, unauthorized, or unrecognized use of the Service;<br />
      (5) the accuracy, completeness, reliability and timeliness of information
      from third-parties and third-party platforms that are used in course of
      the provision of the INST services;<br />
      (6) the respective digital assets network to be available for signing of
      transaction on the blockchain, both in terms of deposit and withdrawal of
      digital assets;<br /><br />
      (7) delays, errors, inadequate transmission speeds or other issues
      regarding the speed of transactions;<br /><br />
      (8) computer viruses, trojan horse, malicious program attacks, network
      congestion, system instability, system or equipment failure,
      telecommunication failure, power failure, banking issues, government
      intervention, etc.; <br /><br />
      (9) any other reasons not imputed to us.<br /><br />
      2. You acknowledge and accept that we shall not be held liable under the
      following circumstances:<br />
      (1) You lose your mobile devices, delete INST without back up, or forget
      password, private key without back up, which result in the loss of digital
      assets;<br />
      (2) You disclose your INST password, private key to others, or authorize
      others to log on to your INST account, or download INST through unofficial
      channels, or use INST by other insecure means, which result in the loss of
      their identity or tokens;<br />
      (3) You are unfamiliar with blockchain technology and your mishandling of
      INST results in loss of your identity or digital assets.<br /><br />
      3. You understand that INST is only an application and is incapable to
      control the quality, security, and legitimacy of products and services
      provided by the third party-developed dApps, or the authenticity and
      accuracy of their information and their capability to fulfill the
      obligations under the agreements with you. You, at your sole discretion,
      decide whether to transact on the third party-developed dApps. You shall
      also assume all the risks arising from the transactions between you and
      any third party. We kindly remind you to carefully review the
      authenticity, legitimacy, and effectiveness of related information
      provided by the third party-developed dApps before you decide to use such
      dApps.<br /><br />
      4. We do not warrant that:<br />
      (1) The Service will satisfy all your needs;<br />
      (2) Techniques, products, and information or other materials on INST will
      meet all your expectations.<br /><br />
      5. You are aware that we do not provide legal, tax, or investment advice.
      You should seek advice from professional legal, tax, and investment
      advisors. In addition, we shall not be liable for any investment loss,
      data loss, etc., during your use of the Service.<br /><br />
      6. You understand that we may change our entry standards, limit the range
      and ways to provide the Service for specific users, etc., at any time in
      accordance with the applicable laws and regulations.<br /><br />
      <br />
      <h2 id="VII. Warranties and representations">
        VII. Warranties and representations
      </h2>
      <br /><br />
      1. By agreeing to this Agreement, you represent, warrant and undertake to
      us that:<br />
      (1) you have full power and authority to enter into this Agreement;<br />
      (2) you understand and acknowledge that we do not warrant that any of the
      Services available through our API are suitable or appropriate for your
      needs and that you must take your own independent legal and other advice
      on such Services;<br />
      (3) you are entering into this Agreement only on behalf of yourself;<br />
      (4) you will not violate any applicable laws by entering into this
      Agreement or receiving the Services provided under it;<br />
      (5) you will not provide false, misleading or inaccurate information;<br />
      (6) you will not facilitate any viruses, malware, worms, trojan horses or
      some other computer programming routines that may damage, corrupt,
      disrupt, misuse or gain unauthorized access to any data, system
      information or INST services;<br />
      (7) funds or Cryptocurrencies transferred to the Wallet or any Sub-Wallet
      have been acquired lawfully;<br />
      (8) you will not use an anonymizing proxy; use any, other automatic
      devices, spider or manual process to copy or monitor our websites without
      our prior written permission;<br />
      (9) you will not harass and/or threaten our employees, agents, or other
      users;<br />
      (10) you understand and acknowledge that while we make reasonable
      endeavours to ensure the accuracy of the information that we provide, and
      which in turn, is provided to you, neither we nor any of our directors,
      employees or agents make any representation or warranty, express or
      implied, as to the accuracy or completeness of such information;<br />
      (11) any information provided by you to INST under this Agreement is true,
      complete, accurate, up to date and not misleading;<br />
      (12) you shall provide all assistance reasonably requested by INST to
      enable INST to comply with its obligations under this Agreement.<br /><br />
      2. INST warrants, represents and undertakes that it shall provide the
      Services with reasonable care and skill.<br /><br />
      3. No Warranties.<br />
      The INST Services are provided on an "as is" and "as available" basis,
      with no further promises made by us around availability of the INST
      Services. Specifically, we do not give any implied warranties of title,
      merchantability, fitness for a particular purpose and/or non-infringement.
      We do not make any promises that access to the Site, any of the INST
      Services, or any of the materials contained therein, will be continuous,
      uninterrupted, timely, or error-free.<br /><br />
      4. INST makes no representation or warranty that the Services are
      applicable or appropriate for use by customers in all jurisdictions and it
      is your responsibility to ensure compliance with the laws of any relevant
      jurisdiction of your residence.<br /><br />
      5. Stored card details<br />
      (1) We are legally obligated to secure your consent to allow us to store
      your card details for future use and cannot process your payment before
      you agree to such storing. By accepting these Terms of Use, you hereby
      give us permission to do so. <br />
      (2) Your card will remain stored against your account for transactional
      purposes, unless you decide to remove it, or until the card expires. For
      legal and regulatory purposes, we will continue to store records of your
      transactions in accordance with our Privacy Policy.<br />
      (3) We need to do this so that you will be able to deposit and withdraw
      more easily in future and it also helps us to prevent and detect any
      fraudulent activity.<br />
      (4) Stored details are primarily used so that you don’t have to re-enter
      all your details for every transaction you make.<br />
      (5) As per our policy, if you don’t agree to us storing your payment card
      details, we won’t be able to process your deposit from the card used.<br />
      (6) Changes in the permitted use will require your agreement, we will
      notify you if this occurs.<br />
      <br />
      <h2 id="VIII.Governing Law and Dispute Resolution">
        VIII.Governing Law and Dispute Resolution
      </h2>
      <br /><br />
      This Terms of Use and the incorporated Privacy Policy, and all claims or
      causes of action (whether in contract, tort, statute or otherwise) that
      may be based on or relate to this Terms of Use and the incorporated
      Privacy Policy are governed by, and shall be construed in accordance with,
      Singapore law, regardless of the law that might otherwise apply under
      applicable principles of conflicts of law.<br /><br />
      <h2 id="IX. Others">IX. Others</h2>
      <br /><br />
      For any complaint relating to the Services, you are advised to contact
      <a href="mailto:support@inst.money"> support@inst.money </a><br /><br />
    </div>
  </DocPage>
</template>

<script>
import DocPage from '@/views/DocPage.vue';

/**
 * @author yuanzeyu
 * @date 2020-02-11
 * @desc
 */
export default {
  name: 'Terms',
  components: { DocPage },
  data() {
    return {
      titles: [
        'Introduction',
        'I. About the Service',
        'II. Your Rights and Obligations',
        'III. Risk Warning',
        'IV. Service Change, Interruption, Termination',
        'V. Your Representations and Warranties',
        'VI.Disclaimer and Limitation of Liability',
        'VII. Warranties and representations',
        'VIII.Governing Law and Dispute Resolution',
        'IX. Others',
      ],
    };
  },
};
</script>
